import timg1 from '../images/team/ceo1.png';
import timg2 from '../images/team/team.png';


const Teams = [
   {
      Id: '1',
      tImg: timg1,
      name: 'Sara Beni',
      slug: 'Sara-Beni',
      title: 'CEO',
   },
   {
      Id: '2',
      tImg: timg2,
      name: 'Miss Kouadio',
      slug: 'Miss-Kouadio',
      title: 'Assistante Exècutive',
   }   
   
]

export default Teams;