
import React, { useState } from 'react';
// import fImg1 from '../../images/faq/faq_img.png';
import fImg1 from '../../images/faq/hero.png';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="faq pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="xb-faq-content">
                            <div className="sec-title mb-125">
                                <h2 className="mb-30 wow skewIn">Questions fréquemment <br /> <span> posées</span></h2>
                                <p>Au cœur de notre engagement à fournir des solutions d'immigration <br /> exceptionnelles représentent notre confiance trusted</p>
                            </div>
                            <div className="faq-img">
                                <img src={fImg1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="xb-faq">
                            <Accordion open={open} toggle={toggle} className='accordion_box'>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="1" className='acc-btn'>Quels services offrez-vous?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1" className='acc_body'>
                                        <div className="content">
                                            <p>Nous proposons des services complets de conseil en immigration et en visa, <br />
                                                y compris l'assistance pour la demande de visa, la préparation des documents,</p>
                                            <ul>
                                                <li><i className="far fa-check"></i>Assistance complète pour les visas</li>
                                                <li><i className="far fa-check"></i>Expertise en matière de catégorie de visa</li>
                                                <li><i className="far fa-check"></i>Transparence et communication</li>
                                            </ul>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="2" className='acc-btn'>Comment se déroule le processus de consultation ?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2" className='acc_body'>
                                        <div className="content">
                                            <p>Nous proposons des services complets de conseil en immigration et en visa, <br />
                                                y compris l'assistance pour la demande de visa, la préparation des documents,</p>
                                            <ul>
                                                <li><i className="far fa-check"></i>Assistance complète pour les visas</li>
                                                <li><i className="far fa-check"></i>Expertise en matière de catégorie de visa</li>
                                                <li><i className="far fa-check"></i>Transparence et communication</li>
                                            </ul>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="3" className='acc-btn'>Combien coûtent vos prestations ?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3" className='acc_body'>
                                        <div className="content">
                                            <p>Nous proposons des services complets de conseil en immigration et en visa, <br />
                                                y compris l'assistance pour la demande de visa, la préparation des documents,</p>
                                            <ul>
                                                <li><i className="far fa-check"></i>Assistance complète pour les visas</li>
                                                <li><i className="far fa-check"></i>Expertise en matière de catégorie de visa</li>
                                                <li><i className="far fa-check"></i>Transparence et communication</li>
                                            </ul>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="4" className='acc-btn'>Comment démarrer avec vos services ?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="4" className='acc_body'>
                                        <div className="content">
                                            <p>Nous proposons des services complets de conseil en immigration et en visa, <br />
                                                y compris l'assistance pour la demande de visa, la préparation des documents,</p>
                                            <ul>
                                                <li><i className="far fa-check"></i>Assistance complète pour les visas</li>
                                                <li><i className="far fa-check"></i>Expertise en matière de catégorie de visa</li>
                                                <li><i className="far fa-check"></i>Transparence et communication</li>
                                            </ul>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="5" className='acc-btn'>Quel est votre taux de réussite avec les demandes de visa ?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="5" className='acc_body'>
                                        <div className="content">
                                            <p>Nous proposons des services complets de conseil en immigration et en visa, <br />
                                                y compris l'assistance pour la demande de visa, la préparation des documents,</p>
                                            <ul>
                                                <li><i className="far fa-check"></i>Assistance complète pour les visas</li>
                                                <li><i className="far fa-check"></i>Expertise en matière de catégorie de visa</li>
                                                <li><i className="far fa-check"></i>Transparence et communication</li>
                                            </ul>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};
export default FaqSection;
