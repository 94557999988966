import React from 'react'
import { Link } from 'react-router-dom'
import icon1 from '../../images/icon/f_call.svg'
import icon2 from '../../images/icon/f_mail.svg'
import icon3 from '../../images/icon/card_img.png'
import Services from '../../api/service'
import Continents from '../../api/continents'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Footer = (props) => {
    return (
        <footer className="site-footer gray-bg pt-65">
            <div className="container">
                <div className="row mt-none-30 pb-60">
                    <div className="col-lg-3 mt-30 col-md-6 footer__custom-col">
                        <div className="footer__widget">
                            <h3 className="widget-title">Avez-vous des questions ou avez-vous demandé plus d'informations? Contactez-nous maintenant</h3>
                            <ul className="footer__cta list-unstyled mt-50">
                                <li className="ul_li"><span><img src={icon1} alt="" /></span>+225 07 58 53 09 79
                                </li>
                                <li className="ul_li"><span><img src={icon1} alt="" /></span>+225 27 22 319 444
                                </li>
                                <li className="ul_li"><span><img src={icon2} alt="" /></span>info@dct-voyage.com
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-30 col-md-6 footer__custom-col">
                        <div className="footer__widget">
                            <h3 className="widget-title">Explorer Liens</h3>
                            <ul className="footer__links list-unstyled">
                                <li><a onClick={ClickHandler} href="https://www.dreamcometrueconsulting.com/student" target='_blank'><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                    viewBox="0 0 13 13" fill="none">
                                    <path d="M6.5 0C6.72067 3.49437 9.5056 6.27934 13 6.5C9.5056 6.72067 6.72067 9.5056 6.5 13C6.27934 9.5056 3.49437 6.72067 0 6.5C3.49437 6.27934 6.27934 3.49437 6.5 0Z"
                                        fill="#B1B4BA" />
                                </svg></span>Etude</a></li>
                                <li><Link onClick={ClickHandler} to="/"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                    viewBox="0 0 13 13" fill="none">
                                    <path d="M6.5 0C6.72067 3.49437 9.5056 6.27934 13 6.5C9.5056 6.72067 6.72067 9.5056 6.5 13C6.27934 9.5056 3.49437 6.72067 0 6.5C3.49437 6.27934 6.27934 3.49437 6.5 0Z"
                                        fill="#B1B4BA" />
                                </svg></span>Tourisme / Visites</Link></li>
                                <li><Link onClick={ClickHandler} to="/tourism"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                    viewBox="0 0 13 13" fill="none">
                                    <path d="M6.5 0C6.72067 3.49437 9.5056 6.27934 13 6.5C9.5056 6.72067 6.72067 9.5056 6.5 13C6.27934 9.5056 3.49437 6.72067 0 6.5C3.49437 6.27934 6.27934 3.49437 6.5 0Z"
                                        fill="#B1B4BA" />
                                </svg></span>Voyages sur mesure</Link></li>
                                <li><Link onClick={ClickHandler} to="/tourism"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                    viewBox="0 0 13 13" fill="none">
                                    <path d="M6.5 0C6.72067 3.49437 9.5056 6.27934 13 6.5C9.5056 6.72067 6.72067 9.5056 6.5 13C6.27934 9.5056 3.49437 6.72067 0 6.5C3.49437 6.27934 6.27934 3.49437 6.5 0Z"
                                        fill="#B1B4BA" />
                                </svg></span>Assurance Voyage</Link></li>
                                <li><a onClick={ClickHandler} href="https://www.dreamcometrueconsulting.com/about" target='_blank'><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                    viewBox="0 0 13 13" fill="none">
                                    <path d="M6.5 0C6.72067 3.49437 9.5056 6.27934 13 6.5C9.5056 6.72067 6.72067 9.5056 6.5 13C6.27934 9.5056 3.49437 6.72067 0 6.5C3.49437 6.27934 6.27934 3.49437 6.5 0Z"
                                        fill="#B1B4BA" />
                                </svg></span>A propos de nous</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-30 col-md-6 footer__custom-col">
                        <div className="footer__widget">
                            <h3 className="widget-title">Nos Services</h3>
                            <ul className="footer__links list-unstyled">
                                {Services.slice(0, 6).map((service, srv) => (
                                    <li key={srv}>
                                        {service.title ?
                                            <Link
                                                onClick={ClickHandler}
                                                to=""
                                            // to={`/service-single/${service.slug}`}
                                            >
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                                    viewBox="0 0 13 13" fill="none">
                                                    <path d="M6.5 0C6.72067 3.49437 9.5056 6.27934 13 6.5C9.5056 6.72067 6.72067 9.5056 6.5 13C6.27934 9.5056 3.49437 6.72067 0 6.5C3.49437 6.27934 6.27934 3.49437 6.5 0Z"
                                                        fill="#B1B4BA" />
                                                </svg></span>{service.title}</Link>
                                            : ''}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-30 col-md-6 footer__custom-col">
                        <div className="footer__widget">
                            <h3 className="widget-title">Nos Destinations</h3>
                            <ul className="footer__links list-unstyled">
                                {Continents.map((continent, cnt) => (
                                    <li key={cnt}><Link onClick={ClickHandler} to={``}><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                        viewBox="0 0 13 13" fill="none">
                                        <path d="M6.5 0C6.72067 3.49437 9.5056 6.27934 13 6.5C9.5056 6.72067 6.72067 9.5056 6.5 13C6.27934 9.5056 3.49437 6.72067 0 6.5C3.49437 6.27934 6.27934 3.49437 6.5 0Z"
                                            fill="#B1B4BA" />
                                    </svg></span>{continent.title}</Link></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer__copyright ul_li_between">
                    <div className="footer__copyright-text mt-15">
                        Copyright © 2024 DCT Consulting Tous droits réservés.
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}} className="footer__copyright-img mt-20">
                        {/* <img src={icon3} alt="" /> */}
                        <span onClick={() => window.open('', '_blank')} style={{ cursor: 'pointer' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-brand-facebook"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 2a1 1 0 0 1 .993 .883l.007 .117v4a1 1 0 0 1 -.883 .993l-.117 .007h-3v1h3a1 1 0 0 1 .991 1.131l-.02 .112l-1 4a1 1 0 0 1 -.858 .75l-.113 .007h-2v6a1 1 0 0 1 -.883 .993l-.117 .007h-4a1 1 0 0 1 -.993 -.883l-.007 -.117v-6h-2a1 1 0 0 1 -.993 -.883l-.007 -.117v-4a1 1 0 0 1 .883 -.993l.117 -.007h2v-1a6 6 0 0 1 5.775 -5.996l.225 -.004h3z" /></svg>
                        </span>
                        <span onClick={() => window.open('', '_blank')} style={{ cursor: 'pointer', paddingLeft: '3%' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" /><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M16.5 7.5l0 .01" /></svg>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;