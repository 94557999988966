import React from 'react';
import VideoModal from '../../ModalVideo';
import { Link } from 'react-router-dom'
import aImg1 from '../../../images/about/trv_img.jpg'
import aImg2 from '../../../images/about/trv_img2.jpg'
import aImg3 from '../../../images/about/trv_img3.jpg'

import aIcon from '../../../images/icon/arrow-right.svg'
import shape from '../../../images/shape/about_shape.png'


const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="about pos-rel pt-125 pb-125">
            <div className="container">
                <div className="row align-items-center mt-none-30">
                    <div className="col-lg-7 mt-30">
                        <div className="xb-about xb-about2">
                            <div className="xb-item--img text-center pos-rel">
                                <div className="xb-item--main-img">
                                    <img src={aImg1} alt="" />
                                </div>
                                <div className="xb-item--sml-img1">
                                    <img src={aImg2} alt="" />
                                </div>
                                <div className="xb-item--sml-img2">
                                    <img src={aImg3} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 mt-30">
                        <div className="xb-travel-about-content">
                            <div className="sec-title sec-title--travel">
                                <span className="subtitle">Voyagez avec Dream Come True Consulting</span>
                                <h2 className="mb-25">Rendez votre voyage plus agréable</h2>
                                <p className="mb-25">
                                    Vivez une expérience de voyages sans soucis avec nous car nous nous efforçons de rendre votre voyage plus agréable. <br /> </p>
                                <p>Partant de la recherche d'itinéraire fluide, d'hébergement et de chaque détail,  <br /> tout en vous permettant de savourer chaque instant<br />et de créé des souvenirs inoubliables</p>
                            </div>
                            <ul className="xb-about-list list-unstyled mt-25 mb-40">
                                <li><i className="far fa-check"></i>Destinations les plus populaires</li>
                                <li><i className="far fa-check"></i>Forfaits économiques</li>
                                <li><i className="far fa-check"></i>Satisfaction garantie à 100%</li>
                            </ul>
                            <div className="ul_li btns">
                                <Link onClick={ClickHandler} className="thm-btn thm-btn--trv" to="/about">Notre histoire<span><img src={aIcon} alt="" /></span></Link>
                                <VideoModal />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="xb-about-shape">
                <img src={shape} alt="" />
            </div>
            <h2 className="xb-strock-text"> A propos de nos voyage </h2>
        </section>
    )
}

export default About;